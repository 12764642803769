<template>
    <a-table
        :columns="columns"
        :data-source="data.sort((a, b) => a.index - b.index)"
        bordered
        size="small"
        :pagination="false"
        :scroll="{ x: 1300 }"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'operateTime'">
                {{ record?.bindInfo?.operateTime }}
            </template>
            <template v-if="column.dataIndex === 'count'">
                {{ record?.count }}
            </template>
            <template v-if="column.dataIndex === 'step'">
                {{ record?.bindInfo?.mesWorkOrderStep?.stepName }}
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";

const columns = [
    {
        title: "序号",
        dataIndex: "index",
        fixed: "left",
        width: 60,
    },
    {
        title: "物料编码",
        dataIndex: "materialCode",
    },
    {
        title: "物料名称",
        dataIndex: "materialName",
    },
    {
        title: "规格型号",
        dataIndex: "materialSpec",
    },
    {
        title: "物料条码",
        dataIndex: "materialBarCode",
    },
    {
        title: "物料厂商",
        dataIndex: "supplier",
    },
    {
        title: "绑定时间",
        dataIndex: "operateTime",
        width: 180,
    },
    {
        title: "绑定工序",
        dataIndex: "step",
    },
    {
        title: "数量",
        dataIndex: "count",
        width: 100,
    },
];
export default defineComponent({
    props: {
        data: {
            default: [],
        },
        routeInfo: {
            default: null,
        },
    },
    setup() {
        const state = reactive({
            previewId: null,
        });

        return {
            columns,
            ...toRefs(state),
        };
    },
});
</script>
