<template>
    <div class="container">
        <UpDateModal v-model:upDateId="upDateId" @eventUpdateOk="eventUpdateOk" />
        <DetailDrawer v-model:detailId="detailId" :tabStatus="status" />
        <Produce v-model:produceId="produceId" @onEmitOk="getTabelData" />
        <BatchPutInto
            v-model:batchPutIntoModalVisible="batchPutIntoModalVisible"
            :workorders="selectedRows"
            @eventUpdateOk="onBatchPutIntoSuccess"
        ></BatchPutInto>
        <a-tabs v-model:activeKey="status" @change="onChangeTab">
            <a-tab-pane
                v-for="item in tabs"
                :key="item.key"
                :tab="item.value + '工单'"
            ></a-tab-pane>
        </a-tabs>
        <FilterBlock @search="search" :produceTeamOptions="produceTeamOptions" />
        <div class="table-content">
            <a-table
                :columns="columns"
                :data-source="data"
                bordered
                size="small"
                :loading="loading"
                :pagination="pagination"
                @change="paginationChange"
                :scroll="{ x: 1500 }"
                :destroyOnClose="true"
                :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"
                class="ant-table-striped"
                :row-selection="
                    batchPutIntoVisible && {
                        selectedRowKeys,
                        onChange: onSelectChange,
                        type: 'checkbox',
                    }
                "
            >
                <template #bodyCell="{ column, record, index }">
                    <template v-if="column.dataIndex === 'index'">
                        {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                    </template>
                    <template v-if="column.dataIndex === 'orderCode'">
                        {{ record?.orderCode || "未关联订单号" }}
                    </template>
                    <template v-if="column.dataIndex === 'progressPercent'">
                        <a-progress :percent="record?.progressPercent" size="small" />
                    </template>
                    <template v-if="column.dataIndex === 'operate'">
                        <a-space direction="horizontal" :size="5">
                            <a @click="() => (detailId = record.id)">详情</a>
                            <a-space
                                v-show="status == (tabs && tabs[0].key)"
                                direction="horizontal"
                                :size="5"
                            >
                                <a
                                    @click="() => (produceId = record.id)"
                                    v-perm="'btn:mes-work-order-producing'"
                                >
                                    投产
                                </a>
                                <a
                                    @click="() => (upDateId = record.id)"
                                    v-perm="'btn:mes-work-order-edit'"
                                >
                                    编辑
                                </a>
                                <a
                                    @click="handleDelete(record.id)"
                                    v-perm="'btn:mes-work-order-delete'"
                                >
                                    删除
                                </a>
                            </a-space>
                        </a-space>
                    </template>
                </template>
                <template #title>
                    <div class="operate-content">
                        <a-space :size="8">
                            <a-button
                                @click="() => (batchPutIntoVisible = false)"
                                v-show="batchPutIntoVisible"
                            >
                                取消
                            </a-button>
                            <a-button
                                type="primary"
                                @click="handleBatchPutInto"
                                v-show="batchPutIntoVisible"
                            >
                                提交
                            </a-button>
                            <a-button
                                type="primary"
                                @click="() => (batchPutIntoVisible = true)"
                                v-show="!batchPutIntoVisible"
                            >
                                {{ batchPutIntoVisible ? "取消" : "" }}批量投产
                            </a-button>
                            <a-button
                                v-perm="'btn:mes-work-order-add'"
                                type="primary"
                                @click="() => (upDateId = '')"
                            >
                                新增
                            </a-button>
                        </a-space>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import FilterBlock from "./filterBlock";
import UpDateModal from "./upDateModal";
import DetailDrawer from "./detailDrawer";
import BatchPutInto from "./batchPutInto";
import Produce from "./produce";
import { confirmModal, showMessage } from "@/utils/common";
import {
    apiProductWorkOrderIndexData,
    apiProductWorkOrderList,
    apiProductWorkOrderDelete,
} from "@/api";
export default defineComponent({
    components: {
        FilterBlock,
        UpDateModal,
        DetailDrawer,

        Produce,
        BatchPutInto,
    },
    setup() {
        const columns = [
            {
                title: "序号",
                dataIndex: "index",
                width: 60,
                fixed: "left",
            },
            {
                title: "工单号",
                dataIndex: "code",
            },
            {
                title: "生产订单号",
                dataIndex: "orderCode",
            },
            {
                title: "项目名称",
                dataIndex: "projectName",
            },
            {
                title: "项目编码",
                dataIndex: "projectCode",
            },
            {
                title: "工单进度",
                dataIndex: "progressPercent",
            },
            {
                title: "产品名称",
                dataIndex: "productName",
            },
            {
                title: "产品编码",
                dataIndex: "productCode",
            },
            {
                title: "规格型号",
                dataIndex: "spec",
            },
            {
                title: "产成品追溯码",
                dataIndex: "mesProductTraceCode",
            },
            {
                title: "集成编号",
                dataIndex: "containerNo",
            },
            {
                title: "生产班组",
                dataIndex: "produceTeamName",
            },
            {
                title: "工艺路线",
                dataIndex: "processName",
            },
            {
                title: "计划投产日期",
                dataIndex: "scheduledStartDate",
            },
            {
                title: "计划完工日期",
                dataIndex: "scheduledEndDate",
            },
            {
                title: "提交时间",
                dataIndex: "createdTime",
            },
            {
                title: "操作",
                dataIndex: "operate",
                fixed: "right",
                width: 200,
            },
        ];

        const state = reactive({
            status: "",
            tabs: [],
            produceTeamOptions: [],
            batchPutIntoVisible: false,
            batchPutIntoModalVisible: false,
            selectedRowKeys: [],
            selectedRows: [],
            upDateId: null,
            detailId: null,

            produceId: null,
            params: null,
            data: [],
            loading: false,
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
                showSizeChanger: false,
            },
        });

        const paginationChange = pagination => {
            state.pagination = pagination;
            getTabelData();
        };

        const getTabelData = async (params = state.params) => {
            state.loading = true;
            const { current: pageNum, pageSize } = state.pagination;
            let res = await apiProductWorkOrderList({
                pageNum,
                pageSize,
                queryParam: {
                    ...params,
                    status: state.status,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.data = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        const onChangeTab = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const search = params => {
            state.params = params;
            state.pagination.current = 1;
            getTabelData(params);
        };

        const eventUpdateOk = () => {
            getTabelData();
        };

        const handleOpenDetail = id => {
            state.detailId = id;
        };

        const handleDelete = id => {
            confirmModal({
                type: "warning",
                content: "工单删除后将无法恢复，是否确认删除该工单？",
                onOk: async () => {
                    let res = await apiProductWorkOrderDelete([id]);
                    if (res.status === "SUCCESS") {
                        showMessage("success", res.msg);
                        getTabelData();
                    }
                },
            });
        };

        const getIndexData = async () => {
            let res = await apiProductWorkOrderIndexData("");
            if (res.status === "SUCCESS") {
                state.produceTeamOptions = res.data?.produceTeamCodeAndNameCandidate;
                state.tabs = res.data?.statusCodeAndNames;
                if (state.tabs?.length) {
                    state.status = state.tabs[0]?.key;
                    getTabelData();
                }
            }
        };
        getIndexData();

        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleBatchPutInto = () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择工单");
            }
            state.batchPutIntoModalVisible = true;
        };

        const onBatchPutIntoSuccess = () => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
            getTabelData();
        };

        return {
            columns,
            ...toRefs(state),
            search,
            paginationChange,
            eventUpdateOk,
            onChangeTab,
            handleOpenDetail,
            handleDelete,
            getTabelData,
            onSelectChange,
            handleBatchPutInto,
            onBatchPutIntoSuccess,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-switch-checked .ant-switch-handle) {
    left: calc(120% - 22px - 4px - 2px);
}
:deep(.ant-tabs-nav) {
    background: #fff;
    margin: 0;
    padding: 0 16px;
    box-sizing: border-box;
}
.container {
    //display: none;
    .table-content {
        background-color: #fff;
        margin-top: 16px;
        padding: 16px;
        box-sizing: border-box;
        .operate-content {
            height: 50px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
    }
}
.ant-table-striped :deep(.table-striped) td {
    background-color: #fafafa;
}
</style>
