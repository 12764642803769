<template>
    <ProductPlanSelect
        v-model:planVisible="planVisible"
        productionLineType="INTEGRATED"
        @select="onSelectedRoute"
    />
    <a-descriptions title=" ">
        <a-descriptions-item label="方案编码">{{ productPlanInfo?.code }}</a-descriptions-item>
        <a-descriptions-item label="方案名称">{{ productPlanInfo?.name }}</a-descriptions-item>
    </a-descriptions>
    <a-descriptions title="质检方案信息">
        <template #extra>
            <a-button type="primary" @click="() => (planVisible = true)">选择</a-button>
        </template>
        <a-descriptions-item label="质检方案编码">
            {{ productPlanInfo?.qcPlanCode }}
        </a-descriptions-item>
        <a-descriptions-item label="质检方案名称">
            {{ productPlanInfo?.qcPlanName }}
        </a-descriptions-item>
    </a-descriptions>
    <a-descriptions title="工艺路线信息">
        <a-descriptions-item label="工艺路线编码">{{ infoData?.code }}</a-descriptions-item>
        <a-descriptions-item label="工艺路线名称">{{ infoData?.name }}</a-descriptions-item>
        <a-descriptions-item label="版本号">{{ infoData?.version }}</a-descriptions-item>
        <a-descriptions-item label="工艺类型" :span="3">
            {{ infoData?.typeDesc }}
        </a-descriptions-item>
        <a-descriptions-item label="附件清单">
            <div class="filebox">
                <a v-for="item in infoData?.files" :href="`${fileURL}/download/${item?.id}`">
                    {{ item?.fileName }}
                </a>
            </div>
        </a-descriptions-item>
    </a-descriptions>
    <a-descriptions title="工序列表"></a-descriptions>
    <a-table
        :columns="columns"
        :data-source="tableData.sort((a, b) => a.index - b.index)"
        bordered
        size="small"
        :pagination="false"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'needDetect'">
                {{ record?.needDetect ? "是" : "否" }}
            </template>
            <template v-if="column.dataIndex === 'productionUnitName'">
                {{ record?.productionUnit?.name }}
            </template>
            <template v-if="column.dataIndex === 'fileIds'">
                <div class="filebox">
                    <a v-for="item in record?.stepFiles" :href="`${fileURL}/download/${item?.id}`">
                        {{ item?.fileName }}
                    </a> 
                </div>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { fileURL } from "@/utils/config";
import { apiRoutingDetail } from "@/api";
import ProductPlanSelect from "../productPlanSelect";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "工序编码",
        dataIndex: "code",
    },
    {
        title: "工序名称",
        dataIndex: "name",
    },
    {
        title: "工序类型",
        dataIndex: "typeDesc",
    },
    {
        title: "工序类别",
        dataIndex: "categoryName",
    },
    {
        title: "质检要求",
        dataIndex: "needDetect",
    },
    {
        title: "生产单元",
        dataIndex: "productionUnitName",
    },
    {
        title: "工序附件",
        dataIndex: "fileIds",
        width: 120,
    },
];
export default defineComponent({
    components: {
        ProductPlanSelect,
    },
    emits: ["selectPlan"],
    setup(props, { emit }) {
        const state = reactive({
            planVisible: false,
            infoData: null,
            productPlanInfo: null,
            checkPlanInfo: null,
            checkItemsList: [],
            tableData: [],
        });

        const getRouteDetail = async id => {
            let res = await apiRoutingDetail(id);
            if (res.status === "SUCCESS") {
                state.infoData = res?.data;
                state.tableData = res?.data?.workSteps.map((item, index) => ({
                    index,
                    ...item,
                    ...item.workStepInfo,
                }));
            }
        };

        const onSelectedRoute = row => {
            if (row) {
                state.productPlanInfo = row;
                const { processId, qcPlanCode, processCode } = row;
                getRouteDetail(processId);
                emit("selectPlan", qcPlanCode, processCode);
            }
        };

        return {
            ...toRefs(state),
            columns,
            fileURL,
            onSelectedRoute,
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
