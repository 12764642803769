<template>
    <div class="serach-block">
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-input v-model:value="code" placeholder="请输入工单号" />
            <a-input v-model:value="orderCode" placeholder="请输入生产订单号" />
            <a-input v-model:value="projectName" placeholder="请输入项目名称" />
            <a-input v-model:value="projectCode" placeholder="请输入项目编码" />
            <a-input v-model:value="productName" placeholder="请输入产品名称" />
            <Select
                v-model:value="produceTeamCode"
                :options="produceTeamOptions"
                labelKey="value"
                valueKey="key"
                placeholder="请选择生产班组"
            />
        </a-space>
        <a-space direction="horizontal" :size="8">
            <a-button type="primary" @click="search">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
    </div>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import Select from "@/components/Select";
export default defineComponent({
    props: ["produceTeamOptions"],
    emits: ["search"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const params = reactive({
            code: undefined,
            orderCode: undefined,
            projectName: undefined,
            projectCode: undefined,
            productName: undefined,
            produceTeamCode: undefined,
        });

        const reset = () => {
            params.code = undefined;
            params.orderCode = undefined;
            params.projectName = undefined;
            params.projectCode = undefined;
            params.productName = undefined;
            params.produceTeamCode = undefined;
            search();
        };

        const search = () => {
            let { code, orderCode, projectName, projectCode, productName } = params;
            params.code = code?.trim();
            params.orderCode = orderCode?.trim();
            params.projectName = projectName?.trim();
            params.projectCode = projectCode?.trim();
            params.productName = productName?.trim();
            emit("search", params);
        };

        return {
            ...toRefs(params),
            search,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-block {
    width: 100%;
    background-color: #fff;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    .serach-space {
        flex: 1;
        display: flex;
        flex-wrap: wrap;
        .ant-input,
        .ant-select {
            width: 160px;
        }
    }
}
</style>
