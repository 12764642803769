import HttpRequest from "@/utils/request";

const axios = new HttpRequest();

// 标准产品方案
const apiStandardProductPlanList = data =>
    axios.request({ url: "/bas-standard-product-plan/find-page", method: "post", data });

const apiStandardProductPlanUpdate = data =>
    axios.request({
        url: `/bas-standard-product-plan/${data?.id ? "update" : "save"}`,
        method: "post",
        data,
    });

const apiStandardProductPlanEnable = id =>
    axios.request({ url: `/bas-standard-product-plan/change-enabled/${id}`, method: "post" });

const apiStandardProductPlanDetail = id =>
    axios.request({ url: `/bas-standard-product-plan/get-info/${id}`, method: "get" });

const apiStandardProductPlanIndexData = id =>
    axios.request({
        url: `/bas-standard-product-plan/get-add-or-update-init-data${id ? "/" + id : ""}`,
        method: "get",
    });

const apiStandardProductPlanDelete = data =>
    axios.request({ url: "/bas-standard-product-plan/delete-by-ids", method: "post", data });

export {
    //标准产品方案
    apiStandardProductPlanList,
    apiStandardProductPlanUpdate,
    apiStandardProductPlanEnable,
    apiStandardProductPlanDetail,
    apiStandardProductPlanIndexData,
    apiStandardProductPlanDelete,
};
