<template>
    <ProductPlanSelect
        v-model:planVisible="planVisible"
        productionLineType="INTEGRATED"
        @select="onSelectedRoute"
    />
    <Teams v-model:teamsVisible="teamsVisible" @select="onSelectedTeam" />
    <a-modal
        v-model:visible="visible"
        title="批量投产"
        @cancel="handleCancel"
        width="600px"
        :footer="null"
        :destroyOnClose="true"
    >
        <div style="margin-bottom: 20px">同一批次批量投产的集成工单生产班组、工艺路线均相同</div>
        <a-form
            ref="formRef"
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="生产班组"
                name="produceTeamName"
                :rules="[{ required: true, message: '请选择生产班组' }]"
            >
                <a-form-item>
                    <div class="my-input-mask" @click="() => (teamsVisible = true)">
                        {{ formData.produceTeamName }}
                    </div>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="产品方案"
                name="checkPlanName"
                :rules="[{ required: true, message: '请选择产品方案' }]"
            >
                <a-form-item>
                    <div class="my-input-mask" @click="() => (planVisible = true)">
                        {{ formData.checkPlanName }}
                    </div>
                </a-form-item>
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 7 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiProductWorkOrderBatchPutinto } from "@/api";
import { showMessage } from "@/utils/common";
import Select from "@/components/Select";
import TeamCategory from "@/components/CategoryModal";
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import Teams from "./teams";
import ProductPlanSelect from "../productPlanSelect";
export default defineComponent({
    props: ["batchPutIntoModalVisible", "workorders"],
    emits: ["eventUpdateOk", "update:batchPutIntoModalVisible"],
    components: {
        Select,
        TeamCategory,
        CloseCircleOutlined,
        Teams,
        ProductPlanSelect,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            planVisible: false,
            teamsVisible: false,
            formRef: null,
            formData: {
                produceTeamName: "",
                produceTeamCode: "",
                routeCode: "",
                checkPlanName: "",
                checkPlanCode: "",
            },
        });

        const handleCancel = () => {
            state.visible = false;
            state.formRef.resetFields();
            emit("update:batchPutIntoModalVisible", false);
        };

        const onFinish = async () => {
            const { produceTeamCode, routeCode, checkPlanCode } = state.formData;
            let res = await apiProductWorkOrderBatchPutinto({
                produceTeamCode,
                processCode: routeCode,
                basQcPlanCode: checkPlanCode,
                ids: props?.workorders?.map(item => item.id),
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const onSelectedRoute = row => {
            const { qcPlanName, qcPlanCode, processCode } = row;
            state.formData.routeCode = processCode;
            state.formData.checkPlanName = qcPlanName;
            state.formData.checkPlanCode = qcPlanCode;
        };

        const onSelectedTeam = team => {
            state.formData.produceTeamName = team?.name;
            state.formData.produceTeamCode = team?.code;
        };

        watch(
            () => props.batchPutIntoModalVisible,
            newVal => {
                if (newVal) {
                    state.visible = true;
                } else {
                    state.visible = false;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            onSelectedTeam,
            onSelectedRoute,
        };
    },
});
</script>

<style lang="less" scoped>
.user-list {
    margin: 10px 0;
    .ant-checkbox-wrapper {
        flex-direction: row-reverse;
        color: #1890ff;
    }
    .operate-content {
        height: 50px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
}
</style>
