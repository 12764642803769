<template>
    <a-descriptions title="工艺路线信息" :column="2">
        <a-descriptions-item label="工艺路线编码">
            {{ routeInfo?.code }}
        </a-descriptions-item>
        <a-descriptions-item label="版本号">
            {{ routeInfo?.version }}
        </a-descriptions-item>
        <a-descriptions-item label="工艺路线名称">
            {{ routeInfo?.name }}
        </a-descriptions-item>
        <a-descriptions-item label="工艺类型">
            {{ routeInfo?.typeDesc }}
        </a-descriptions-item>
        <a-descriptions-item label="工艺路线附件">
            <div class="filebox">
                <a
                    v-for="item in routeInfo?.processFiles"
                    :href="`${fileURL}/download/${item?.id}`"
                >
                    {{ item?.fileName }}
                </a>
            </div>
        </a-descriptions-item>
    </a-descriptions>
    <a-descriptions title="工序列表"></a-descriptions>
    <a-table
        :columns="columns"
        :data-source="tableData.sort((a, b) => a.index - b.index)"
        bordered
        size="small"
        :pagination="false"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 }}
            </template>
            <template v-if="column.dataIndex === 'needDetect'">
                {{ record?.needDetect ? "是" : "否" }}
            </template>
            <template v-if="column.dataIndex === 'productionUnitName'">
                {{ record?.productionUnitJson?.name }}
            </template>
            <template v-if="column.dataIndex === 'workReportPassedSchedule'">
                <span :style="{ color: getStatusColor(record?.status) }">
                    {{ record?.workReportPassedSchedule }} %
                </span>
            </template>
            <template v-if="column.dataIndex === 'fileIds'">
                <div v-for="item in record?.files" :key="item?.id">
                    <a :href="`${fileURL}/download/${item?.id}`">
                        {{ item?.fileName }}
                    </a>
                </div>
            </template>
        </template>
    </a-table>
</template>

<script>
import { defineComponent, reactive, toRefs } from "vue";
import { fileURL } from "@/utils/config";
import { getStatusColor } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
    },
    {
        title: "工序编码",
        dataIndex: "code",
    },
    {
        title: "工序名称",
        dataIndex: "name",
    },
    {
        title: "工序类型",
        dataIndex: "typeDesc",
    },
    {
        title: "工序类别",
        dataIndex: "categoryName",
    },
    {
        title: "质检要求",
        dataIndex: "needDetect",
    },
    {
        title: "质检结果",
        dataIndex: "checkPass",
    },
    {
        title: "生产单元",
        dataIndex: "productionUnitName",
    },
    {
        title: "报工进度",
        dataIndex: "workReportPassedSchedule",
    },
    {
        title: "工序附件",
        dataIndex: "fileIds",
        width: 120,
    },
];
export default defineComponent({
    props: {
        tableData: {
            default: [],
        },
        routeInfo: {
            default: null,
        },
    },
    setup() {
        return {
            columns,
            fileURL,
            getStatusColor,
        };
    },
});
</script>

<style lang="less" scoped>
.filebox {
    display: flex;
    flex-wrap: wrap;
    a {
        margin-right: 10px;
    }
}
</style>
