<template>
    <a-space class="serach-space" direction="horizontal" :size="8">
        <a-input v-model:value="code" placeholder="请输入流水号以检索" />
        <Select
            v-model:value="stepCode"
            :options="stepOptions"
            labelKey="value"
            valueKey="key"
            placeholder="请选择工序"
        />
        <a-button type="primary" @click="handleSearch">搜索</a-button>
    </a-space>
    <a-table
        :columns="columns"
        :data-source="tableData"
        :loading="loading"
        bordered
        size="small"
        :pagination="pagination"
        @change="paginationChange"
    >
        <template #bodyCell="{ column, record, index }">
            <template v-if="column.dataIndex === 'index'">
                {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
            </template>
            <template v-if="column.dataIndex === 'statusDesc'">
                <span :style="{ color: getStatusColor(record?.status) }">
                    {{ record?.statusDesc }}
                </span>
            </template>
            <template v-if="column.dataIndex === 'rework'">
                {{ record?.rework ? "是" : "否" }}
            </template>
            <template v-if="column.dataIndex === 'schedule'">{{ record?.schedule }} %</template>
            <template v-if="column.dataIndex === 'workReportUsers'">
                {{ record?.workReportUsers?.length }}
            </template>
            <template v-if="column.dataIndex === 'reportDate'">
                {{ formateMoment(record?.createdTime, "YYYY-MM-DD") }}
            </template>
            <template v-if="column.dataIndex === 'reportTime'">
                {{ formateMoment(record?.productionStartTime, "HH:mm") }} ~
                {{ formateMoment(record?.productionEndTime, "HH:mm") }}
            </template>
        </template>
    </a-table>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiJCWorkReportList, apiJCWorkReportIndexData } from "@/api";
import Select from "@/components/Select";
import { formateMoment, getStatusColor } from "@/utils/common";

const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "报工流水号",
        dataIndex: "code",
    },
    {
        title: "提交人",
        dataIndex: "creatorDisplayName",
    },
    {
        title: "报工工序",
        dataIndex: "workOrderStepName",
    },
    {
        title: "生产进度",
        dataIndex: "schedule",
    },
    {
        title: "生产人数",
        dataIndex: "workReportUsers",
    },
    {
        title: "小时工人数",
        dataIndex: "hourlyWorkerCount",
    },
    {
        title: "生产日期",
        dataIndex: "reportDate",
    },
    {
        title: "生产时长(/h)",
        dataIndex: "reportDurationHours",
    },
    {
        title: "生产总工时(/h)",
        dataIndex: "reportDurationTotalHours",
    },
    {
        title: "异常时长(/h)",
        dataIndex: "exceptionWorkingHours",
    },
    {
        title: "异常总工时(/h)",
        dataIndex: "exceptionTotalWorkingHours",
    },
    {
        title: "提交时间",
        dataIndex: "createdTime",
    },
];

export default defineComponent({
    props: {
        workOrderCode: {
            default: "",
        },
    },
    components: {
        Select,
    },
    setup(props) {
        const state = reactive({
            loading: false,
            tableData: [],
            code: "",
            stepCode: undefined,
            stepOptions: [],
            keyWords: "",
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
            },
        });

        const getIndexData = async () => {
            let res = await apiJCWorkReportIndexData();
            if (res.status === "SUCCESS") {
                state.stepOptions = res?.data?.stepCodeAndNameCandidate;
            }
        };
        getIndexData();

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                code,
                stepCode,
            } = state;
            const data = {
                pageNum,
                pageSize,
                queryParam: {
                    code: code.trim(),
                    stepCode,
                    workOrderCode: props?.workOrderCode,
                },
            };
            let res = await apiJCWorkReportList(data);
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data?.totalRecord;
                state.tableData = res.data?.recordList;
            }
        };

        const handleSearch = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const paginationChange = pagination => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
            state.pagination = pagination;
            getTabelData();
        };

        watch(
            () => props?.workOrderCode,
            newVal => newVal && getTabelData(),
            {
                immediate: true,
            }
        );

        return {
            columns,
            ...toRefs(state),
            paginationChange,
            getTabelData,
            handleSearch,
            formateMoment,
            getStatusColor,
        };
    },
});
</script>

<style lang="less" scoped>
.serach-space {
    margin-right: 16px;
    margin-bottom: 16px;
    .ant-input,
    .ant-select {
        width: 160px;
    }
}
</style>
