<template>
    <ProductList v-model:productListVisible="productListVisible" @onSelect="onSelectProduct" />
    <OrderList
        v-model:orderListModalVisible="orderListModalVisible"
        orderType="INTEGRATED"
        @select="onSelectOrder"
    />
    <WorkorderCategory
        :data="workorderCategoryOptions"
        typeName="工单"
        v-model:categoryVisible="workorderCategoryVisible"
        @onSelect="onChangeWorkorderCategory"
    />
    <a-modal
        v-model:visible="visible"
        :title="`${upDateId ? '编辑' : '新增'}工单`"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
        width="700px"
    >
        <a-form
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 17 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="生产订单号"
                name="orderId"
                :rules="[{ required: !formData.relatedOrders, message: '请选择生产订单号' }]"
            >
                <div class="contant-item">
                    <SelectInput
                        :title="produceOrderCode"
                        :onClick="handleOpenOrderList"
                        :disabled="formData.relatedOrders || Boolean(upDateId)"
                        :showDeleteIcon="false"
                        style="width: 320px; top: -5px"
                    ></SelectInput>
                    <a-checkbox
                        v-model:checked="formData.relatedOrders"
                        :disabled="Boolean(upDateId)"
                    >
                        不关联订单
                    </a-checkbox>
                </div>
            </a-form-item>

            <a-form-item label="工单号" name="code">
                <a-input
                    v-model:value="formData.code"
                    :disabled="true"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item label="工单类别" name="categoryName">
                <SelectInput
                    :title="formData.categoryName"
                    :onClick="() => (workorderCategoryVisible = true)"
                    @onDelete="() => onChangeWorkorderCategory()"
                ></SelectInput>
            </a-form-item>

            <a-form-item
                label="产品编码"
                name="productCode"
                :rules="[{ required: true, message: '请选择产品' }]"
            >
                <a-form-item>
                    <div class="contant-item">
                        <a-input
                            style="width: 330px"
                            v-model:value="formData.productCode"
                            :disabled="true"
                            :maxlength="100"
                            showCount
                        />
                        <a-button
                            :disabled="!formData.relatedOrders"
                            type="primary"
                            @click="() => (productListVisible = true)"
                        >
                            查询
                        </a-button>
                    </div>
                </a-form-item>
            </a-form-item>

            <a-form-item
                label="产品名称"
                name="productName"
                :rules="[{ required: true, message: '请选择产品' }]"
            >
                <a-input
                    v-model:value="formData.productName"
                    :disabled="true"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item label="规格型号" name="spec">
                <a-input
                    v-model:value="formData.spec"
                    :disabled="true"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item
                label="产成品追溯码"
                name="mesProductTraceCode"
                :rules="[{ required: true, message: '请输入产成品追溯码' }]"
            >
                <a-input
                    v-model:value="formData.mesProductTraceCode"
                    :disabled="true"
                    :maxlength="100"
                    showCount
                />
            </a-form-item>

            <a-form-item
                label="集成编号"
                name="containerNo"
                :rules="[
                    { required: true, message: '请输入集成编号' },
                    { validator: checkSpaceStartEnd },
                ]"
            >
                <a-input v-model:value="formData.containerNo" :maxlength="100" showCount />
            </a-form-item>

            <a-form-item label="预计投产日期" name="scheduledStartDate">
                <a-date-picker
                    v-model:value="formData.scheduledStartDate"
                    valueFormat="YYYY-MM-DD"
                    :disabled-date="disabledStartDate"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item label="预计完成日期" name="scheduledEndDate">
                <a-date-picker
                    v-model:value="formData.scheduledEndDate"
                    valueFormat="YYYY-MM-DD"
                    :disabled-date="disabledEndDate"
                    style="width: 100%"
                />
            </a-form-item>

            <a-form-item label="备注" name="remarks" :rules="[{ validator: checkSpaceAll }]">
                <a-textarea
                    v-model:value="formData.remarks"
                    placeholder="最多可输入800字"
                    :rows="4"
                    :maxlength="800"
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 5 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiProductWorkOrderGetUpdateData, apiProductWorkOrderSavaUpdateData } from "@/api";
import Select from "@/components/Select";
import OrderList from "@/components/OrderList";
import ProductList from "@/components/ProductList";
import WorkorderCategory from "@/components/CategoryModal";
import { showMessage, checkValueIsAvailable, getAutoCreateCode } from "@/utils/common";
import { DownOutlined } from "@ant-design/icons-vue";
import SelectInput from "@/components/SelectInput";
import dayjs from "dayjs";
export default defineComponent({
    props: ["upDateId"],
    emits: ["eventUpdateOk", "update:upDateId"],
    components: {
        Select,
        OrderList,
        WorkorderCategory,
        DownOutlined,
        ProductList,
        SelectInput,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            orderListModalVisible: false,
            productListVisible: false,
            workorderCategoryVisible: false,
            produceTeamOptions: [],
            produceRoutingOptions: [],
            workorderCategoryOptions: [],
            produceOrderCode: "",
            formData: {
                id: "",
                code: "",
                orderId: "",
                mesProductTraceCode: "",
                relatedOrders: false,
                projectName: "",
                productName: "",
                productCode: "",
                categoryName: "",
                categoryCode: "",
                spec: "",
                containerNo: "",
                scheduledStartDate: "",
                scheduledEndDate: "",
                remarks: "",
            },
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:upDateId", null);
        };

        const onSelectOrder = ({ id, produceOrderCode, unit, spec, productName, productCode }) => {
            state.formData.orderId = id;
            state.produceOrderCode = produceOrderCode;
            state.formData.unit = unit;
            state.formData.productName = productName;
            state.formData.spec = spec;
            state.formData.productCode = productCode;
        };

        const onFinish = async () => {
            const reqData = {
                ...state.formData,
                relatedOrders: !state.formData.relatedOrders,
            };
            let res = await apiProductWorkOrderSavaUpdateData(reqData);
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("eventUpdateOk");
            }
        };

        const getIndexData = async id => {
            let res = await apiProductWorkOrderGetUpdateData(id);
            if (res.status === "SUCCESS") {
                let { mesWorkOrder, categoryName } = res.data;
                state.workorderCategoryOptions = categoryName;
                state.produceOrderCode = mesWorkOrder?.orderCode;
                Object.keys(state.formData).forEach(key => {
                    state.formData[key] = mesWorkOrder ? mesWorkOrder[key] : "";
                });
                state.formData.relatedOrders = mesWorkOrder
                    ? !Boolean(mesWorkOrder?.orderId)
                    : false;
            }
        };

        const checkSpaceStartEnd = (rule, value) => {
            if (value && !checkValueIsAvailable(value, "spaceStartEnd")) {
                return Promise.reject("不能以空格开头或结尾");
            } else {
                return Promise.resolve();
            }
        };

        const checkSpaceAll = (rule, value) => {
            if (value && checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能全是空格");
            } else {
                return Promise.resolve();
            }
        };

        const onSelectProduct = ([product]) => {
            state.formData.productCode = product?.code;
            state.formData.productName = product?.name;
            state.formData.spec = product?.spec;
        };

        watch(
            () => props.upDateId,
            async newVal => {
                if (newVal !== null) {
                    state.visible = true;
                    await getIndexData(newVal);
                    if (!newVal) {
                        state.formData.code = await getAutoCreateCode("MES_WORK_ORDER");
                        state.formData.mesProductTraceCode = await getAutoCreateCode(
                            "MES_WORK_ORDER_SN_NO"
                        );
                    }
                } else {
                    state.visible = false;
                }
            }
        );

        const disabledStartDate = current => {
            const { scheduledEndDate } = state.formData;
            if (!scheduledEndDate) {
                return false;
            }
            return current && current >= dayjs(scheduledEndDate).endOf("day");
        };

        const disabledEndDate = current => {
            const { scheduledStartDate } = state.formData;
            if (!scheduledStartDate) {
                return false;
            }
            return current && current < dayjs(scheduledStartDate).startOf("day");
        };

        const handleOpenOrderList = () => {
            if (props.upDateId) return;
            if (!state.formData.relatedOrders) {
                state.orderListModalVisible = true;
            }
        };

        const onChangeWorkorderCategory = arr => {
            const [value] = arr || [];
            state.formData.categoryName = value ? value?.name : "";
            state.formData.categoryCode = value ? value?.code : "";
        };

        const handleClearWorkorderCategory = () => {
            state.formData.categoryName = "";
            state.formData.categoryCode = "";
        };

        watch(
            () => state.formData.relatedOrders,
            newVal => {
                if (newVal && !props.upDateId) {
                    state.formData.orderId = "";
                    state.produceOrderCode = "";
                    state.formData.productName = "";
                    state.formData.productCode = "";
                    state.formData.spec = "";
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkSpaceStartEnd,
            onSelectOrder,
            disabledStartDate,
            disabledEndDate,
            handleOpenOrderList,
            checkSpaceAll,
            onChangeWorkorderCategory,
            onSelectProduct,
            handleClearWorkorderCategory,
        };
    },
});
</script>

<style lang="less" scoped>
.contant-item {
    display: flex;
    justify-content: space-between;
}
.mask {
    position: absolute;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 32px;
    height: 32px;
    border-radius: 2px;
    width: 320px;
    cursor: pointer;
    position: absolute;
    opacity: 0;
    z-index: 999;
}
</style>
