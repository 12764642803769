<template>
    <a-modal
        v-model:visible="visible"
        title="产品方案列表"
        width="1200px"
        okText="提交"
        :destroyOnClose="true"
        @cancel="handleCancel"
        @ok="handleSubmit"
    >
        <a-space style="margin-bottom: 20px" direction="horizontal" :size="8">
            <a-input v-model:value="code" placeholder="请输入产品方案编码" />
            <a-input v-model:value="name" placeholder="请输入产品方案名称" />
            <a-button type="primary" @click="handleSearch">搜索</a-button>
            <a-button type="primary" @click="reset" danger>重置</a-button>
        </a-space>
        <a-table
            :columns="columns"
            :data-source="tableData"
            bordered
            size="small"
            :loading="loading"
            :row-selection="{
                selectedRowKeys,
                onChange: onSelectChange,
                type: 'radio',
            }"
            :pagination="pagination"
            @change="paginationChange"
        >
            <template #bodyCell="{ column, record, index }">
                <template v-if="column.dataIndex === 'index'">
                    {{ index + 1 + (pagination.current - 1) * pagination.pageSize }}
                </template>
                <template v-if="column.dataIndex === 'needDetect'">
                    {{ record.needDetect ? "是" : "否" }}
                </template>
            </template>
        </a-table>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs, computed } from "vue";
import { apiStandardProductPlanList } from "@/api/baseInfo";
import Select from "@/components/Select";
import { showMessage } from "@/utils/common";
const columns = [
    {
        title: "序号",
        dataIndex: "index",
        width: 60,
        fixed: "left",
    },
    {
        title: "标准产品方案编码",
        dataIndex: "code",
    },
    {
        title: "标准产品方案名称",
        dataIndex: "name",
    },
    {
        title: "工艺路线编码",
        dataIndex: "processCode",
    },
    {
        title: "工艺路线名称",
        dataIndex: "processName",
    },
    {
        title: "成品检验方案编码",
        dataIndex: "qcPlanCode",
    },
    {
        title: "成品检验方案名称",
        dataIndex: "qcPlanName",
    },
];
export default defineComponent({
    props: ["planVisible", "productionLineType"],
    emits: ["update:planVisible", "selectedRows"],
    components: {
        Select,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            nameLike: "",
            tableData: [],
            selectedRowKeys: [],
            selectedRows: [],
            code: "",
            name: "",
            pagination: {
                total: 0,
                current: 1,
                pageSize: 8,
            },
        });

        const reset = () => {
            state.code = undefined;
            state.name = undefined;
            state.pagination.current = 1;
            getTabelData();
        };

        const handleCancel = () => {
            state.pagination.current = 1;
            state.visible = false;
            state.selectedRowKeys = [];
            state.selectedRows = [];
            emit("update:planVisible", false);
        };

        const getTabelData = async () => {
            state.loading = true;
            const {
                pagination: { current: pageNum, pageSize },
                name,
                code,
            } = state;
            let res = await apiStandardProductPlanList({
                pageNum,
                pageSize,
                queryParam: {
                    code: code?.trim(),
                    name: name?.trim(),
                    productionLineType: props.productionLineType,
                    enabled: true,
                },
            });
            state.loading = false;
            if (res.status === "SUCCESS") {
                state.pagination.total = res.data.totalRecord;
                state.tableData = res.data.recordList.map((item, index) => ({
                    ...item,
                    key: index,
                }));
            }
        };

        const handleSearch = () => {
            state.pagination.current = 1;
            getTabelData();
        };

        const paginationChange = pagination => {
            state.selectedRowKeys = [];
            state.selectedRows = [];
            state.pagination = pagination;
            getTabelData();
        };

        const onSelectChange = (selectedRowKeys, selectedRows) => {
            state.selectedRowKeys = selectedRowKeys;
            state.selectedRows = selectedRows;
        };

        const handleSubmit = () => {
            if (!state.selectedRows.length) {
                return showMessage("info", "请先选择产品方案");
            }
            emit("select", state.selectedRows[0], props.planVisible);
            handleCancel();
        };

        watch(
            () => props.planVisible,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    state.pagination.current = 1;
                    getTabelData();
                }
            }
        );

        return {
            columns,
            ...toRefs(state),
            handleCancel,
            paginationChange,
            getTabelData,
            handleSubmit,
            onSelectChange,
            handleSearch,
            reset,
        };
    },
});
</script>

<style lang="less" scoped>
.search-input {
    margin-bottom: 16px;
    width: 300px;
}
</style>
